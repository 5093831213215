class Accordion {
  constructor() {
    this.accordionTabs = document.querySelectorAll('.js-faq-accordion-tab')
    this.activeClass = 'is-active'
    this.events()
  }

  events = () => {
    if (!this.accordionTabs) return
    let _this = this
    this.accordionTabs.forEach(tab => {
      tab.addEventListener('click', function() {
        if (tab.classList.contains(_this.activeClass))
          tab.classList.remove(_this.activeClass) 
        else {
          tab.classList.add(_this.activeClass) 
          _this.accordionTabs.forEach(siblingTab => {
            // if (siblingTab != this)
            //   siblingTab.classList.remove(_this.activeClass)
          })
        }
      })
    })
  }
}

export default Accordion