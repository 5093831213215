import Glide from '@glidejs/glide'
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css'

class MobileOnlyCarousel {
  constructor() {
    this.carousels = document.querySelectorAll('.js-mobile-only-carousel')
    this.events()
  }

  events = () => {
    if (!this.carousels.length) return
    this.initCarousels()
  }

  initCarousels = () => {
    this.carousels.forEach(carousel => {
      new Glide(carousel, {
        type: 'slider',
        peek: parseInt(carousel.dataset.peek) || 50,
        rewind: false,
        gap: parseInt(carousel.dataset.gap) || 50,
        perView: 1.1,
        animationTimingFunc: 'cubic-bezier(.65,.05,.36,1)',
        animationDuration: 600,
        breakpoints: {
          1024: {
            perView: 2.1
          },
          767: {
            perView: 1.1
          }
        }
      }).mount()
    })
  }
}

export default MobileOnlyCarousel