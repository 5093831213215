class CaseStudiesAPI {
  constructor() {
    this.loadMore       = document.querySelector('.js-case-study-load-more')
    this.postOutput     = document.querySelector('.js-post-output')
    this.loadingSpinner = document.querySelector('.js-loading-spinner')
    this.isLoading      = false
    this.perPage        = 12
    this.postCount      = this.perPage
    this.timer
    this.events()
  }

  events = () => {
    if (!this.loadMore)
      return
    let _this = this
    this.loadMore.addEventListener('click', function() {
      _this.postCount = _this.postCount + _this.perPage
      _this.loadingHandler()
      _this.loadMorePosts()
    });
  }

  loadingHandler = () => {
    if (!this.isLoading) {
      this.loadingSpinner.style.display = 'flex'
      this.isLoading = true
    }
  }

  fetchData = async () => {
    try {
      const searchResponse = await fetch(`${pdScripts.baseURL}/wp-json/pd/v1/case-studies?page=${this.postCount}`)
      const data = await searchResponse.json()
      return data
    }
    catch(err) {
      console.log(err)
    }
  }

  disableLoadMoreButton = () => {
    if (this.loadMore)
      this.loadMore.classList.add('is-disabled')
  }

  enableLoadMoreButton = () => {
    if (this.loadMore)
      this.loadMore.classList.remove('is-disabled')
  }

  loadMorePosts = () => {
    this.disableLoadMoreButton()
    this.fetchData().then(data => {
      if (!data[0])
        return
      this.enableLoadMoreButton()
      this.loadingSpinner.style.display = 'none'
      this.isLoading = false
      // show/hide Show more button
      if (this.loadMore) {
        if (data.more_posts)
          this.loadMore.style.display = 'flex'
        else
          this.loadMore.style.display = 'none'
      }
      // format output
      let output = ''
      data[0].forEach(post => {
        output += `<a href="${post.permalink}" class="[ post-item ] js-animate-on-scroll">
          <span class="post-item__thumbnail">
            <img src="${post.thumbnail}" loading="lazy" />
          </span>
          <h5 class="post-item__title">
            ${post.title}
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="28" height="28" rx="14" fill="#fff"/>
              <line x1="20" y1="14" x2="8" y2="14" stroke="#0F1016" stroke-width="2" stroke-linecap="round"/>
              <path d="M14 7.25781L20.7426 14.0004L14 20.743" stroke="#0F1016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </h5>
          <p>${post.excerpt}</p>
        </a>`
      })
      this.postOutput.innerHTML = output
    }).catch(err => console.log(err))
  }
}

export default CaseStudiesAPI