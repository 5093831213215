// CSS entry point
import "../css/main.scss"

// Plugins
import 'lazysizes'

// JS partials
import Perspective from "./modules/Perspective"
import PostSlider from "./modules/PostSlider"
import TestimonialSlider from "./modules/TestimonialSlider"
import Cookie from "./modules/Cookie"
import AnimateOnScroll from "./modules/AnimateOnScroll"
import PostsAPI from "./modules/PostsAPI"
import CaseStudiesAPI from "./modules/CaseStudiesAPI"
import PoliciesAPI from "./modules/PoliciesAPI"
import Video from "./modules/Video"
import MobileOnlyCarousel from "./modules/MobileOnlyCarousel"
import Navigation from "./modules/Navigation"
import Accordion from "./modules/Accordion"

var is_chrome = navigator.userAgent.indexOf('Chrome') > -1
var is_safari = navigator.userAgent.indexOf("Safari") > -1

if ((is_chrome) && (is_safari)) 
  is_safari = false

// Init
const init = () => {
  new AnimateOnScroll()
  new PostSlider()
  new TestimonialSlider()
  new Cookie()
  new PostsAPI()
  new CaseStudiesAPI()
  new PoliciesAPI()
  new Video()
  new MobileOnlyCarousel()
  new Navigation()
  new Accordion()

  window.addEventListener("load", (event) => {
    console.log("page is fully loaded");
  });

  if (!is_safari)
    new Perspective()
}

init()