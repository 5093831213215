import Glide from '@glidejs/glide'
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css'

class PostsAPI {
  constructor() {
    this.postCarousel        = document.querySelector('.js-post-grid-carousel')
    this.postCarouselBullets = document.querySelector('.js-post-grid-bullets')
    this.searchInput         = document.querySelector('.js-search-term')
    this.searchResults       = document.querySelector('.js-search-results')
    this.loadingSpinner      = document.querySelector('.js-loading-spinner')
    this.loadMore            = document.querySelector('.js-load-more')
    this.filters             = document.querySelectorAll('.js-post-filter')
    this.filterPostOutput    = document.querySelector('.js-search-results')
    this.clearFiltersBtn     = document.querySelector('.js-clear-filters')
    this.mobilePostFilter    = document.querySelector('.js-post-filter-mobile')
    this.activeFilters       = []
    this.selectedClass       = 'is-selected'
    this.isLoading           = false
    this.perPage             = 12
    this.postCount           = this.perPage
    this.timer
    this.events()

    if (this.postCarousel) {
      this.carouselInstance    = new Glide(this.postCarousel, {
        type: 'slider',
        peek: parseInt(this.postCarousel.dataset.peek) || 50,
        rewind: false,
        gap: parseInt(this.postCarousel.dataset.gap) || 50,
        perView: 1.1,
        animationTimingFunc: 'cubic-bezier(.65,.05,.36,1)',
        animationDuration: 600,
        breakpoints: {
          1024: {
            perView: 2.1
          },
          767: {
            perView: 1.1
          }
        }
      }).mount()
    }
  }

  events = () => {
    if (!this.searchInput || !this.filters || !this.postCarousel) return
    let _this = this
    // keyword search input
    this.searchInput.addEventListener('keyup', this.inputHandler)
    // post filter selection
    this.filters.forEach(filter => {
      filter.addEventListener('click', function(e) {
        _this.clearFiltersBtn.classList.remove(_this.selectedClass)
        // control button selected state
        if (filter.classList.contains(_this.selectedClass))
          filter.classList.remove(_this.selectedClass)
        else 
          filter.classList.add(_this.selectedClass)
        let taxTermID = e.target.getAttribute('data-term-id')
        // add term to array unless is already exists, then remove it
        if (!_this.activeFilters.includes(taxTermID))
          _this.activeFilters.push(taxTermID)
        else
          _this.activeFilters.splice(_this.activeFilters.indexOf(taxTermID), 1)
        // set loading state
        _this.loadingHandler()
        _this.getSearchResults()
      })
    })
    // clear filters button
    this.clearFiltersBtn.addEventListener('click', function(e) {
      this.classList.add(_this.selectedClass)
      // clear active filters
      _this.activeFilters = []
      // reset selected state
      _this.filters.forEach(filter => {
        filter.classList.remove(_this.selectedClass)
      })
      _this.loadingHandler()
      _this.getSearchResults()
    })
    // load more posts
    if (this.loadMore) {
      this.loadMore.addEventListener('click', function() {
        _this.postCount = _this.postCount + _this.perPage
        _this.loadingHandler()
        _this.getSearchResults()
      })
    }
    // mobile post filters
    if (this.mobilePostFilter) { 
      this.mobilePostFilter.addEventListener('change', function(e) {
        _this.mobilePostFilterHandler(e)
        _this.loadingHandler()
        _this.getSearchResults()
      })
    }
  }

  loadingHandler = () => {
    if (!this.isLoading) {
      this.loadingSpinner.style.display = 'flex'
      this.isLoading = true
    }
  }

  // fire search on text input
  inputHandler = () => {
    clearTimeout(this.timer)
    this.loadingHandler()
    this.timer = setTimeout(this.getSearchResults, 500)
  }

  disableLoadMoreButton = () => {
    if (this.loadMore)
      this.loadMore.classList.add('is-disabled')
  }

  enableLoadMoreButton = () => {
    if (this.loadMore)
      this.loadMore.classList.remove('is-disabled')
  }

  // REST API-friendly string format
  activeFiltersString = () => {
    if (!this.activeFilters.length)
      return
    let output = ''
    let newFilters = []
    // check that ids array is not empty otherwise remove object
    newFilters = this.activeFilters.filter(f => f.ids.length)
    // loop over remaining objects that contain ids
    newFilters.forEach(filter => {
      output += `${filter.term}=${filter.ids.join(',')}&`
    })
    return output
  }

  fetchData = async () => {
    // create search params
    let stringParams = ''
    let filterString = this.activeFilters.toString()

    // search input and selected filters
    if (this.searchInput.value && this.activeFilters.length)
      stringParams = `?term=${this.searchInput.value}&cat=${filterString}&page=${this.postCount}`
    // search input with no selected filters
    else if (this.searchInput.value && !this.activeFilters.length)
      stringParams = `?term=${this.searchInput.value}&page=${this.postCount}`
    // no search input with selected filters
    else if (!this.searchInput.value && this.activeFilters.length)
      stringParams = `?cat=${filterString}&page=${this.postCount}`
    // no search input and no selected filters (show more only)
    else if (!this.searchInput.value && !this.activeFilters.length)
      stringParams = `?page=${this.postCount}`

    try {
      const searchResponse = await fetch(`${pdScripts.baseURL}/wp-json/pd/v1/search${stringParams}`)
      const data = await searchResponse.json()
      return data
    }
    catch(err) {
      console.log(err)
    }
  }

  getSearchResults = () => {
    this.disableLoadMoreButton()
    this.fetchData().then(data => {
      this.enableLoadMoreButton()
      this.loadingSpinner.style.display = 'none'
      this.isLoading = false
      if (!data[0].length) {
        // display no results message
        this.searchResults.innerHTML = `<p style="grid-column: 1 / 5;">Sorry, no results were found matching your search criteria.</p>`
        // hide "Show more"
        if (this.loadMore)
          this.loadMore.style.display = 'none'
      } else {
        if (this.loadMore) {
        // show/hide "Show more"
          if (data.more_posts)
            this.loadMore.style.display = 'flex'
          else
            this.loadMore.style.display = 'none'
        }
        // format output
        let output = ''
        let bullets = ''
        data[0].forEach(post => {
          output += `<li class="[ glide__slide ] [ js-animate-on-scroll ]">
            <a href="${post.permalink}" class="grid-item">
              <span class="grid-item__thumbnail grid-item__thumbnail--square">
                <img src="${post.thumbnail}" loading="lazy" />
                <span class="grid-item__category">${post.category}</span>
              </span>
              <h5 class="grid-item__title">
                ${post.title}
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="28" height="28" rx="14" fill="#fff"/>
                  <line x1="20" y1="14" x2="8" y2="14" stroke="#0F1016" stroke-width="2" stroke-linecap="round"/>
                  <path d="M14 7.25781L20.7426 14.0004L14 20.743" stroke="#0F1016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </h5>
              <p>${post.excerpt}</p>
            </a>
          </li>`
        })
        // format bullets
        data[0].forEach((post, i) => {
          bullets += `<button class="glide__bullet" data-glide-dir="${i}"></button>`
        })
        this.searchResults.innerHTML = output
        this.postCarouselBullets.innerHTML = bullets
        // update glide instance
        this.carouselInstance.update()
      }
    }).catch(err => console.log(err))
  }

  // mobile-specific post filter UI
  mobilePostFilterHandler = e => {
    this.activeFilters.length = 0
    if (!e.target.value)
      this.activeFilters = []
    else
      this.activeFilters.push(e.target.value)
  }
}

export default PostsAPI