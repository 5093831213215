import { lerp } from "./Utils"

class PostSlider {
  constructor() {
    this.slider = document.querySelector('.js-post-slider')
    this.dragCursor = document.querySelector('.js-drag-cursor')
    this.isDown = false
    this.startX
    this.scrollLeft
    this.activeClass = 'is-active'
    this.movingClass = 'is-moving'
    this.cursorX = 0
    this.cursorY = 0
    this.events()
  }
  
  events() {
    if (!this.slider) return 

    document.addEventListener('mousemove', e => {
      e.preventDefault()
      this.cursorX = lerp(this.cursorX, e.pageX, 0.3)
      this.cursorY = lerp(this.cursorY, e.pageY, 0.3)
    })

    this.slider.addEventListener('mousedown', e => {
      e.preventDefault()
      this.isDown = true
      this.slider.classList.add(this.activeClass)
      this.startX = e.pageX - this.slider.offsetLeft
      this.scrollLeft = this.slider.scrollLeft
      this.dragCursor.classList.add(this.activeClass)

    })

    this.slider.addEventListener('mouseleave', () => {
      this.isDown = false
      this.slider.classList.remove(this.activeClass, this.movingClass)
      this.cursorHide()
    })

    this.slider.addEventListener('mouseup', () => {
      this.isDown = false
      this.slider.classList.remove(this.activeClass, this.movingClass)
      this.dragCursor.classList.remove(this.activeClass)
    })

    this.slider.addEventListener('mousemove', e => {
      e.preventDefault()
      this.cursorTrack()
      if (!this.isDown) return 
      const x = e.pageX - this.slider.offsetLeft
      const walk = (x - this.startX) * 2
      this.slider.scrollLeft = this.scrollLeft - walk
      this.slider.classList.add(this.movingClass)
    })

    // touch events
    this.slider.addEventListener('touchstart', e => {
      this.isDown = true
      this.slider.classList.add(this.activeClass)
      this.startX = e.pageX - this.slider.offsetLeft
      this.scrollLeft = this.slider.scrollLeft
    })

    this.slider.addEventListener('touchend', () => {
      this.isDown = false
      this.slider.classList.remove(this.activeClass, this.movingClass)
    })

    this.slider.addEventListener('touchcancel', () => {
      this.isDown = false
      this.slider.classList.remove(this.activeClass, this.movingClass)
    })

    this.slider.addEventListener('touchmove', e => {
      if (!this.isDown) return  // stop the fn from running
      e.preventDefault()
      const x = e.pageX - this.slider.offsetLeft
      const walk = (x - this.startX) * 2
      this.slider.scrollLeft = this.scrollLeft - walk
      this.slider.classList.add(this.movingClass)
    })
  }

  cursorTrack() {
    this.dragCursor.style.display = 'block'
    this.dragCursor.style.left = `${this.cursorX}px`
    this.dragCursor.style.top = `${this.cursorY}px`
  }

  cursorHide() {
    this.dragCursor.style.display = 'none'
  }
}

export default PostSlider