import Accordion from "./Accordion"

class PoliciesAPI {
  constructor() {
    this.searchInput    = document.querySelector('.js-policy-search-term')
    this.policyResults  = document.querySelector('.js-policy-results')
    this.loadingSpinner = document.querySelector('.js-loading-spinner')
    this.showAll        = document.querySelector('.js-policy-show-all')
    this.isLoading      = false
    this.timer
    this.accordion
    this.events()
  }
  
  events() {
    // filter posts by search input value (always searches all posts in this instance)
    if (!this.searchInput)
      return 
    this.searchInput.addEventListener('keyup', this.inputHandler)
    // fetch 'all' policy posts
    if (!this.showAll)
      return
    let _this = this
    this.showAll.addEventListener('click', function() {
      _this.loadingHandler()
      _this.getSearchResults(true)
    });
  }

  loadingHandler = () => {
    if (!this.isLoading) {
      this.loadingSpinner.style.display = 'flex'
      this.isLoading = true
      this.disableLoadMoreButton()
    }
  }

  inputHandler = () => {
    clearTimeout(this.timer)
    this.loadingHandler()
    this.timer = setTimeout(this.getSearchResults, 500)
  }

  fetchData = async (fetchAll = false) => {
    let stringParams = ``
    if (this.searchInput.value)
      stringParams = `?term=${this.searchInput.value}&show_all`
    if (fetchAll)
      stringParams = `?show_all`
    try {
      const searchResponse = await fetch(`${pdScripts.baseURL}/wp-json/pd/v1/policies${stringParams}`)
      const data = await searchResponse.json()
      return data
    }
    catch(err) {
      console.log(err)
    }
  }

  disableLoadMoreButton = () => {
    if (this.showAll)
      this.showAll.classList.add('is-disabled')
  }

  enableLoadMoreButton = () => {
    if (this.showAll)
      this.showAll.classList.remove('is-disabled')
  }

  getSearchResults = (fetchAll) => {
    this.fetchData(fetchAll).then(data => {
      this.enableLoadMoreButton()
      this.loadingSpinner.style.display = 'none'
      this.isLoading = false
      if (!data[0].length) {
        // display no results message
        this.policyResults.innerHTML = `<p style="grid-column: 1 / 5;">Sorry, no results were found matching your search criteria.</p>`
        // hide "Show more"
        if (this.showAll)
          this.showAll.style.display = 'none'
      } else {
        if (this.showAll) {
        // show/hide "Show more"
          if (data.more_posts)
            this.showAll.style.display = 'flex'
          else
            this.showAll.style.display = 'none'
        }
        // format output
        let output = ``
        data[0].forEach(policy => {
          output += `<div class="[ row justify-content-center ] [ accordion-item accordion-item--policy ] js-policy js-animate-on-scroll">
            <div class="[ col col-12 col-md-4 ] [ accordion-item__tab ] js-faq-accordion-tab">
              <h3>${policy.title}</h3>
              <a href="${policy.file}" target="_blank" class="[ button button--primary ]">Download</a>
            </div>
            <div class="[ col col-12 col-md-6 ] [ accordion-item__content ]">
              <div class="[ col-11 col-md-10 ]">
                <p>${policy.excerpt}</p>
                <a href="${policy.file}" target="_blank" class="[ button button--primary ]">Download</a>
              </div>
            </div>
          </div>`
        })
        this.policyResults.innerHTML = output
        // reinit accordion
        const a = new Accordion()
        a.constructor()
      }
    }).catch(err => console.log(err))
  }
}

export default PoliciesAPI