class Navigation {
  constructor() {
    this.burger      = document.querySelector('.js-nav-burger')
    this.navMenu     = document.querySelector('.js-nav-menu')
    this.closeNav    = document.querySelector('.js-nav-menu-close')
    this.activeClass = 'is-active'
    this.events()
  }

  events = () => {
    this.burger.addEventListener('click', this.openMobileNavPanel)
    this.closeNav.addEventListener('click', this.closeMobileNavPanel)
  }

  openMobileNavPanel = () => {
    this.navMenu.classList.add(this.activeClass)
    document.body.classList.add('disable-scroll')
  }

  closeMobileNavPanel = () => {
    this.navMenu.classList.remove(this.activeClass)
    document.body.classList.remove('disable-scroll')
  }
}

export default Navigation