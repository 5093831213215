import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

class AnimateOnScroll {
  constructor() {
    this.elements = document.querySelectorAll('.js-animate-on-scroll')
    this.animateElements()
  }

  animateElements = () => {
    if (!this.elements)
      return
    this.elements.forEach(element => {
      gsap.fromTo(element, {
          autoAlpha: 0, 
          y: 20
        }, {
        scrollTrigger: {
          trigger: element,
          once: true
        },
        duration: 1, 
        autoAlpha: 1, 
        y: 0
      });
    });
  }
}

export default AnimateOnScroll