class Cookie {
  constructor() {
    this.popup = document.querySelector('.js-cookie-popup')
    this.acceptBtn = document.querySelector('.js-cookie-accept')
    this.events()
  }

  events() {
    if (!this.popup) return 
    this.acceptBtn.addEventListener('click', this.setCookie)
    this.popupHandler()
  }

  popupHandler = () => {
    if (!localStorage.getItem('hcfx-cookie')) {
      setTimeout(() => {
        this.popup.classList.add('is-active')
      }, 3000)
    }
  }

  setCookie = () => {
    this.popup.classList.remove('is-active')
    localStorage.setItem('hcfx-cookie', true)
  }
}

export default Cookie