import Glide from '@glidejs/glide'
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css'

class TestimonialSlider {
  constructor() {
    this.slider   = document.querySelector('.js-testimonial-slider')
    this.slides   = document.querySelector('.js-testimonial-slides')
    this.articles = document.querySelectorAll('.js-testimonial-article')
    this.events()
  }

  events = () => {
    if (this.slider && this.slides) {
      this.initSlider()
      window.dispatchEvent(new Event('resize'))
    }
  }

  initSlider = () => {
    let glideInstance = new Glide(this.slider, {
      type: 'carousel',
      animationDuration: 0
    }).mount()

    // next/prev
    let sliderPrev = document.querySelector('.js-glide-arrow-prev')
    let sliderNext = document.querySelector('.js-glide-arrow-next')

    sliderPrev.addEventListener('click', () => {
      glideInstance.go('<')
    })

    sliderNext.addEventListener('click', () => {
      glideInstance.go('>')
    })

    this.setSlideHeight()
  }

  // prevent slider height jumping between slides w/ diff heights
  setSlideHeight = () => {
    let h = 0
    if (window.matchMedia("(min-width: 1025px)").matches) {
      this.articles.forEach(article => {
        const articleHeight = article.getBoundingClientRect().height
        if (h < articleHeight) h = articleHeight
        this.slides.style.height = `${h}px`
      })
    } else {
      this.slides.style.height = 'auto'
    }
  }
}

export default TestimonialSlider