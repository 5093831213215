class Video {
  constructor() {
    this.videos         = document.querySelectorAll('.js-video')
    this.isPlayingClass = 'is-playing'
    this.events()
  }

  events() {
    if (!this.videos.length) return 
    let _this = this;
    this.videos.forEach(video => {
      let isPlaying = false
      video.addEventListener('click', function() {
        if (!isPlaying) {
          this.setAttribute('controls', '')
          this.parentNode.classList.add(_this.isPlayingClass)
        } else {
          this.removeAttribute('controls')
          this.parentNode.classList.remove(_this.isPlayingClass)
          this.pause()
        }
        isPlaying = !isPlaying
      })
      video.addEventListener('playing', function() {
        this.play()
      })
      video.addEventListener('ended', function() {
        _this.resetHandler(this)
      })
    })
  }

  resetHandler = (instance) => {
    instance.currentTime = 0
    instance.removeAttribute('controls')
    instance.parentNode.classList.remove(this.isPlayingClass)
  }
}

export default Video