import gsap, { Power1 } from "gsap"

class Perspective {
  constructor() {
    this.elements = document.querySelectorAll('.js-follow-mousemove')
    this.init()
  }

  init() {
    if (!this.elements.length)
      return 

    document.addEventListener('mousemove', e => {
      let x = (e.clientX / window.innerWidth) - 0.5
      let y = (e.clientY / window.innerHeight) - 0.5

      this.elements.forEach(el => {
        gsap.to(el, {
          rotationY: 7*x,
          rotationX: 7*y,
          duration: 0.6,
          ease: Power1.easeOut,
          transformPerspective: 500,
          transformOrigin: "center"
        })
      })
    })
  }
}

export default Perspective